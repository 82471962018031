<div
  *ngIf="isDataReady"
  appModalCloseOnClick
  [ngClass]="{'modal-blur-box border-bottom-radius-3' : isMobile, 'h-100': !isOpenedInModal}"
  [appBackgroundUrl]="!isOpenedInModal ? loginBackground : 'none'"
  class="modal-body p-0 flex-content-vc-hc flex-column"
>
  <div
    [ngClass]="{
      'w-100': isMobile,
      'modal-content': isMobile && isOpenedInModal,
      'min-h-100vh flex-content-vc-hc flex-column': !isMobile || !isOpenedInModal,
      'modal': isOpenedInModal,
      'mobile-page': isMobile && !isOpenedInModal
    }">
    <div appModalCloseOnClickPrevent [ngClass]="{'modal-box': !isMobile, 'w-100': isMobile}">
      <div
        *ngIf="!(isLogin || isSignup)"
        [ngClass]="{'user-authentication': !isMobile, 'user-authentication-mobile': isMobile}"
        class="text-center padding-0-4rem"
      >
        <input ngbAutofocus type="hidden"/>

        <div class="flex-content-hc">
          <div class="flex-content-vc-hc mmmm-icon-rounded">
            <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
          </div>
        </div>

        <div class="mt-4 pt-4 font-weight-normal text-white">
          <span>Continue with</span>
        </div>

        <div class="flex-content-hc">
          <div class="user-authentication-buttons mt-4">
            <button
              class="btn-google btn rounded-pill btn-outline-white btn-block text-transform-none"
              (click)="onLoginClick('google')"
            >
              Google
            </button>

            <button
              appModalCloseOnClickPrevent
              class="btn-facebook btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
              (click)="onLoginClick('facebook')"
            >
              Facebook
            </button>

            <button
              appModalCloseOnClickPrevent
              class="btn-email btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
              (click)="signupEnable()"
            >
              Email
            </button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isMobile && (isLogin || isSignup)">
        <div class="user-authentication w-100 h-100">
          <div class="w-100 padding-0-4rem">
            <input ngbAutofocus type="hidden"/>

            <div appModalCloseOnClickPrevent class="flex-content-hc">
              <div class="flex-content-vc-hc mmmm-icon-rounded">
                <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
              </div>
            </div>

            <div appModalCloseOnClickPrevent *ngIf="isSignup">
              <form
                *ngIf="signupStep.REGISTER == userSignupStep"
                [formGroup]="registerForm"
                (ngSubmit)="onDefaultLoginClick()"
                class="mt-4 pt-4"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="e-mail-des"
                        class="d-flex justify-content-start text-white"
                      >
                        Email
                      </label>

                      <input
                        [ngClass]="{'is-invalid': fr.email.errors && submittedSignUp}"
                        type="email"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="e-mail-des"
                        formControlName="email"
                      />
                      <small class="invalid-feedback text-white text-align-start">
                        {{
                          fr.email.errors?.required ? 'Required' :
                            fr.email.errors?.exists ? 'This email already has an account' :
                              'Invalid email address'
                        }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-2">
                  <button class="col-12 btn btn-white text-black btn-sign-in">Sign up</button>
                </div>
              </form>

              <ng-container *ngIf="signupStep.CHECK_EMAIL == userSignupStep">
                <div class="text-white mt-4 pt-4">Check inbox/spam to complete sign up</div>

                <button
                  class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3 pt-2"
                  (click)="onResendClicked()"
                >
                  Resend confirmation link
                </button>

                <button
                  class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
                  (click)="changeEmail()"
                >
                  Change email
                </button>
              </ng-container>

              <form
                *ngIf="signupStep.CREATE_USERNAME == userSignupStep"
                [formGroup]="usernameForm"
                (ngSubmit)="onNextClick()"
                class="mt-4 pt-4"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="username-des"
                        class="d-flex justify-content-start text-white"
                      >
                        Create username
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': usernameControl.errors && (submitted || usernameChecked)}"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        type="text"
                        id="username-des"
                        formControlName="username"
                      >

                      <small class="invalid-feedback text-danger text-align-start">
                        {{
                          usernameControl.errors?.required ? 'Required' :
                            usernameControl.errors?.pattern ? 'Letters, numbers, underscores and periods only' :
                              'Username already exists'
                        }}
                      </small>

                      <small *ngIf="!submitted" class="form-text text-white-50">
                        Letters, numbers, underscores and periods only
                      </small>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12">
                    <button class="col-12 btn btn-white text-black btn-sign-in" type="submit">
                      Next
                    </button>
                  </div>
                </div>
              </form>

              <form
                *ngIf="signupStep.FINISH == userSignupStep"
                [formGroup]="finishForm"
                (ngSubmit)="onFinishClick()"
                class="mt-4 pt-4"
              >
                <input ngbAutofocus type="hidden"/>

                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <label
                        for="first-name-des"
                        class="d-flex justify-content-start text-white"
                      >
                        First name
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': finishFormControls.firstName.errors && finishSignUpSubmitted}"
                        [appFocus]="true"
                        type="text"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="first-name-des"
                        formControlName="firstName"
                      />

                      <small class="invalid-feedback text-white text-align-start">Required</small>
                    </div>

                    <div class="col-6">
                      <label
                        for="last-name-des"
                        class="d-flex justify-content-start text-white"
                      >
                        Last name
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': finishFormControls.lastName.errors && finishSignUpSubmitted}"
                        type="text"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="last-name-des"
                        onfocus="this.removeAttribute('readonly');"
                        readonly
                        formControlName="lastName"
                      />
                      <small class="invalid-feedback text-white text-align-start">Required</small>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="signin-password-des"
                        class="d-flex justify-content-start text-white"
                      >
                        Password
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': finishFormControls.password.errors && finishSignUpSubmitted}"
                        type="password"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="signin-password-des"
                        formControlName="password"
                        onfocus="this.removeAttribute('readonly');" readonly
                      />
                      <small class="invalid-feedback text-white text-align-start">
                        {{
                          finishFormControls.password.errors && finishFormControls.password.errors.required ?
                            'Required' :
                            'Needs to be at least 6 characters'
                        }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="col-12 btn btn-white text-black btn-sign-in">Finish</button>
                </div>
              </form>
            </div>

            <div appModalCloseOnClickPrevent *ngIf="isLogin">
              <form [formGroup]="loginForm" (ngSubmit)="onDefaultLoginClick()" class="mt-4 pt-4">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="email-des"
                        class="d-flex justify-content-start text-white"
                      >
                        Email
                      </label>

                      <input
                        id="email-des"
                        [ngClass]="{ 'is-invalid': fl.email.errors && submittedLogin}"
                        [appFocus]="true"
                        type="email"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        formControlName="email"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="password-des"
                        class="d-flex justify-content-start text-white"
                      >
                        Password
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': fl.password.errors && submittedLogin}"
                        id="password-des"
                        type="password"
                        class="form-control form-border-white btn-outline-white outline-none pl-12px"
                        formControlName="password"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="col-12 btn-login btn btn-white text-black btn-sign-in">Login</button>
                </div>
              </form>
            </div>

            <div
              *ngIf="isLogin || signupStep.REGISTER == userSignupStep"
              class="form-group mb-0 d-flex justify-content-between"
            >
              <a
                *ngIf="isLogin"
                appHrefNone
                class='pl-0 modal-title cursor-pointer opacityOnHover login-link'
                (click)="loginAndSignupSwitch()"
              >
                {{ isLogin ? 'Sign up' : 'Login' }}
              </a>

              <a
                *ngIf="isLogin"
                appHrefNone
                class='pr-0 text-white modal-title cursor-pointer opacityOnHover'
              >
                Forgot password
              </a>
            </div>

            <div
              *ngIf="signupStep.REGISTER == userSignupStep"
              [ngClass]="{'d-none': isLogin}"
              class="w-100 text-center text-white"
            >
              or
            </div>

            <button
              *ngIf="signupStep.REGISTER == userSignupStep "
              [ngClass]="{'d-none': isLogin}"
              class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-2"
              (click)="instagramClick()"
            >
              Claim my Instagram username
            </button>

            <div *ngIf="!isLogin && signupStep.REGISTER == userSignupStep" class="form-group mb-0 mt-3 d-flex">
              <button
                class='login-link btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"'
                (click)="loginAndSignupSwitch()"
              >
                Login with email
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isMobile && (isLogin || isSignup)">
        <div class="user-authentication-mobile bg-transparent w-100 h-100">
          <div appModalCloseOnClickPrevent class="w-100 padding-0-4rem">
            <input ngbAutofocus type="hidden"/>

            <div class="flex-content-hc">
              <div
                *ngIf="userSignupStep != signupStep.FINISH"
                class="flex-content-vc-hc"
                [ngClass]="{
                  'mmmm-icon-rounded mb-4': userSignupStep == signupStep.CHECK_EMAIL,
                  'mmmm-icon-rounded-small': userSignupStep != signupStep.CHECK_EMAIL
                }"
              >
                <span appHrefNone class="icon-v2 mmmm-2x-red"></span>
              </div>
            </div>

            <ng-container *ngIf="isSignup">
              <form
                *ngIf="signupStep.REGISTER == userSignupStep" [formGroup]="registerForm"
                (ngSubmit)="onDefaultLoginClick()"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="e-mail"
                        class="d-flex justify-content-start text-white"
                      >
                        Email
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': fr.email.errors && submittedSignUp}"
                        type="email"
                        class="form-control form-border-white btn-outline-white pl-12px bg-white"
                        id="e-mail"
                        formControlName="email"
                      />

                      <small class="invalid-feedback text-white text-align-start">
                        {{
                          fr.email.errors?.required ? 'Required' :
                            fr.email.errors?.exists ? 'This email already has an account' :
                              'Invalid email address'
                        }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-2">
                  <button class="col-12 btn btn-primary btn-sign-in text-white">Sign up</button>
                </div>
              </form>

              <ng-container *ngIf="signupStep.CHECK_EMAIL == userSignupStep">
                <div class="text-white mt-3">Check inbox/spam to complete sign up</div>

                <button
                  class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3 pt-2"
                  (click)="onResendClicked()"
                >
                  Resend confirmation link
                </button>

                <button
                  class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
                  (click)="changeEmail()"
                >
                  Change email
                </button>
              </ng-container>

              <form
                *ngIf="signupStep.CREATE_USERNAME == userSignupStep"
                [formGroup]="usernameForm"
                (ngSubmit)="onNextClick()"
                class="mt-4 pt-4"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="username"
                        class="d-flex justify-content-start text-white"
                      >
                        Create username
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': usernameControl.errors && (submitted || usernameChecked)}"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        type="text"
                        id="username"
                        formControlName="username"
                      >

                      <small class="invalid-feedback text-danger text-align-start">
                        {{
                          usernameControl.errors?.required ? 'Required' :
                            usernameControl.errors?.pattern ? 'Letters, numbers, underscores and periods only' :
                              'Username already exists'
                        }}
                      </small>

                      <small *ngIf="!submitted" class="form-text text-white-50">
                        Letters, numbers, underscores and periods only
                      </small>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12">
                    <button class="col-12 btn btn-white text-black btn-sign-in">
                      Next
                    </button>
                  </div>
                </div>
              </form>

              <form
                *ngIf="signupStep.FINISH == userSignupStep"
                [formGroup]="finishForm"
                (ngSubmit)="onFinishClick()"
                class="mt-4"
              >
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <input ngbAutofocus type="hidden"/>

                      <label
                        for="first-name"
                        class="d-flex justify-content-start text-white"
                      >
                        First name
                      </label>

                      <input
                        [ngClass]="{
                          'is-invalid': finishFormControls.firstName.errors &&
                           finishSignUpSubmitted
                        }"
                        [appFocus]="true"
                        type="text"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="first-name"
                        onfocus="this.removeAttribute('readonly');" readonly
                        formControlName="firstName"
                      />
                      <small class="invalid-feedback text-white text-align-start">Required</small>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12">
                      <label
                        for="last-name"
                        class="d-flex justify-content-start text-white"
                      >
                        Last name
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': finishFormControls.lastName.errors && finishSignUpSubmitted}"
                        type="text"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="last-name"
                        onfocus="this.removeAttribute('readonly');" readonly
                        formControlName="lastName"
                      />

                      <small class="invalid-feedback text-white text-align-start">Required</small>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="sign-in-password"
                        class="d-flex justify-content-start text-white"
                      >
                        Create password
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': finishFormControls.password.errors && finishSignUpSubmitted}"
                        type="password"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        id="sign-in-password"
                        onfocus="this.removeAttribute('readonly');" readonly
                        formControlName="password"
                      />

                      <small class="invalid-feedback text-white text-align-start">
                        {{
                          finishFormControls.password.errors && finishFormControls.password.errors.required ?
                            'Required' :
                            'Needs to be at least 6 characters'
                        }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="col-12 btn btn-white text-black btn-sign-in">Finish</button>
                </div>
              </form>
            </ng-container>

            <ng-container *ngIf="isLogin">
              <form [formGroup]="loginForm" (ngSubmit)="onDefaultLoginClick()">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="email"
                        class="d-flex justify-content-start text-white"
                      >
                        Email
                      </label>

                      <input
                        id="email"
                        [ngClass]="{ 'is-invalid': fl.email.errors && submittedLogin}"
                        [appFocus]="true"
                        type="email"
                        class="form-control form-border-white btn-outline-white bg-white pl-12px"
                        formControlName="email"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <label
                        for="password"
                        class="d-flex justify-content-start text-white"
                      >
                        Password
                      </label>

                      <input
                        [ngClass]="{ 'is-invalid': fl.password.errors && submittedLogin}"
                        id="password"
                        type="password"
                        class="form-control form-border-white btn-outline-white pl-12px"
                        style="outline: none !important;"
                        formControlName="password"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="col-12 btn btn-primary btn-sign-in text-white">Login</button>
                </div>
              </form>
            </ng-container>

            <div
              *ngIf="isLogin || signupStep.REGISTER == userSignupStep"
              class="form-group mb-0 d-flex justify-content-between"
            >
              <a
                *ngIf="isLogin"
                appHrefNone
                class='pl-0 text-white modal-title cursor-pointer'
                (click)="loginAndSignupSwitch()"
              >
                {{ isLogin ? 'Sign up' : 'Login' }}
              </a>

              <a
                *ngIf="isLogin"
                appHrefNone
                class='pr-0 text-white modal-title cursor-pointer'
              >
                Forgot password
              </a>
            </div>

            <ng-container *ngIf="signupStep.REGISTER == userSignupStep && isSignup">
              <div class="w-100 text-center text-white"> or</div>

              <button
                class="btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"
                (click)="instagramClick()"
              >
                Claim my Instagram username
              </button>

              <div class="form-group mb-0 mt-3 d-flex">
                <button
                  class='btn rounded-pill btn-outline-white btn-block text-transform-none mt-3"'
                  (click)="loginAndSignupSwitch()"
                >
                  Login with email
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="m-auto"></div>

    <div *ngIf="signupStep.FINISH != userSignupStep" class="privacy text-center text-white mb-4 cursor-pointer">
      By signing up you agree to our

      <a routerLink="/terms" class="text-white text-decoration-underline">Terms,</a>

      <a
        appHrefNone routerLink="/privacy"
        class="text-white text-decoration-underline"
      >Privacy & Cookies Policy</a>
    </div>
  </div>
</div>

<ng-template #usernameChoiceModalRef let-c="close" let-d="dismiss">
  <app-username-choice-modal [user]="newUser" (username)="onUsernameSelected($event)"></app-username-choice-modal>
</ng-template>
