export enum PickerCustomOption {
  EXPLORE_SEARCH = 'SEARCH',
  RESTAURANT_LINK_VIDEO = 'LINK_VIDEO',
  RESTAURANT_EDIT = 'EDIT',
  RESTAURANT_CALL = 'CALL',
  RESTAURANT_MAP = 'MAP',
  RESTAURANT_IMAGE_SEARCH = 'IMAGE_SEARCH',
  MENU_WEB_IMAGES = 'WEB_IMAGES',
  MENU_PHOTOS_MAP = 'PHOTOS_MAP',
  USER_EDIT = 'EDIT',
  USER_DELETE = 'DELETE',
  DISTANCE_CLEAR = 'CLEAR',
  FOOD_DISCLAIMER = 'DISCLAIMER',
  FOOD_ALLERGENS = 'ALLERGENS',
  ADMIN_DASHBOARD = 'DASHBOARD',
  ADMIN_BRAND = 'BRAND',
  ADMIN_ABOUT = 'About',
  ADMIN_MARKETING = 'Marketing',
  ADMIN_ACCOUNT = 'Account',
  ADMIN_PASSWORD = 'Password',
  ADMIN_LOGOUT = 'Logout'
}
