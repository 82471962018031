<label *ngIf="position.isMobile" class="search-filters-mobile-label mb-0 position-relative mr-2">
  <select
    #countySelectRef
    appAutoResizeSelect
    required
    aria-label="County"
    class="custom-select custom-select-mobile hide-arrow"
    formControlName="countyId"
    (change)="onCountyChange($event.target)"
    [ngClass]="{'text-red': !countySelected}"
  >
    <option value="null" hidden [selected]="!countySelected">County</option>

    <ng-container *ngIf="stateSelected">
      <ng-container *ngIf="countySelect.isGrouped">
        <ng-container *ngFor="let option of countySelect.options;">
          <option *ngIf="countySelectRef.value == option.value" [value]="option.value">
            {{ option.label }}
          </option>
        </ng-container>

        <optgroup [label]="nearByLabel">
          <ng-container *ngFor="let option of countySelect.options;">
            <option
              *ngIf="option.group == nearByLabel && countySelectRef.value != option.value"
              [value]="option.value"
            >
              {{ option.label }}
            </option>
          </ng-container>
        </optgroup>

        <optgroup [label]="otherLabel">
          <ng-container *ngFor="let option of countySelect.options">
            <option *ngIf="option.group == otherLabel" [value]="option.value">
              {{ option.label }}
            </option>
          </ng-container>
        </optgroup>
      </ng-container>

      <ng-container *ngIf="!countySelect.isGrouped">
        <option *ngFor="let option of countySelect.options" [value]="option.value">
          {{ option.label }}
        </option>
      </ng-container>
    </ng-container>
  </select>
</label>

<div *ngIf="position.isDesktopNavbar">
  <label
    [ngClass]="{'visibility-hidden': stateSelected == null}"
    class="mb-0 text-left mr-1-6rem"
  >
    <ng-select
      #ngSelectCountyComponent
      appNgSelectOnHover
      appModalCloseOnClickPrevent
      [items]="countySelect.options"
      [searchable]="false"
      [ngClass]="'ng-select-explore-search-title-under-logo dark'"
      [hideSelected]="true"
      groupBy="group"
      class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
      bindLabel="label"
      bindValue="value"
      placeholder="County"
      formControlName="countyId"
      (change)="onCountyChange($event)"
    ></ng-select>
  </label>
</div>
