import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutoResizeSelect]'
})
export class AutoResizeSelectDirective implements AfterViewInit {
  private measurer: HTMLSpanElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.measurer = this.renderer.createElement('span');
    this.renderer.setStyle(this.measurer, 'position', 'absolute');
    this.renderer.setStyle(this.measurer, 'visibility', 'hidden');
    this.renderer.setStyle(this.measurer, 'white-space', 'nowrap');
    document.body.appendChild(this.measurer);
  }

  ngAfterViewInit() {
    this.updateWidth();
  }

  @HostListener('change') onChange() {
    this.updateWidth();
  }

  @HostListener('load') onLoad() {
    this.updateWidth();
  }

  private updateWidth() {
    setTimeout(() => {
      const selectElement = this.el.nativeElement as HTMLSelectElement;
      this.measurer.innerText = selectElement.options[selectElement.selectedIndex].text;

      const textWidth = this.measurer.offsetWidth;
      this.renderer.setStyle(selectElement, 'width', `${textWidth}px`);
    });
  }
}
