import { PickerConfig } from './picker-config';
import { PickerGroup } from './picker-group';
import { PickerOption } from './picker-option';

export class Picker {
  optionGroups: PickerGroup[] = [];

  constructor(config: PickerConfig = null) {
    const defaultGroup = new PickerGroup();
    defaultGroup.isDefault = true;
    this.optionGroups.push(defaultGroup);

    if (config?.groups && config.groups.length > 0) {
      config.groups.forEach(group => {
        const pickerGroup = new PickerGroup(group.label);

        group.options.forEach(option => {
          pickerGroup.options.push(
            new PickerOption(option.value, option.label, option.returnObject ? option.returnObject : null)
          );
        });

        this.optionGroups.push(pickerGroup);
      });
    } else {
      if (config?.options && config.options.length > 0) {
        config.options.forEach(option => {
          this.addDefaultOption(option.value, option.label);
        });
      }
    }
  }

  get defaultGroup(): PickerGroup {
    return this.optionGroups.find(group => group.isDefault);
  }

  get additionalGroups(): PickerGroup[] {
    return this.optionGroups.filter(group => !group.isDefault);
  }

  addDefaultOption(value: string, label: string, returnObject: any = null) {
    this.defaultGroup.addOption(value, label, returnObject);
  }
}
