<div class="restaurant-preview-footer">
  <div class="d-flex flex-content-vc-hc h-100">
    <div class="footer-icon">
      <i class="icon-v2 share-dark mt-3px" (click)="onClickShare()"></i>
    </div>

    <div class="footer-icon" (click)="onHoursToggleClick()">
      <i *ngIf="!isHoursEnabled" class="icon-v2 clock-dark"></i>

      <i *ngIf="isHoursEnabled && !isBasket" class="icon-v2 play"></i>

      <i *ngIf="isHoursEnabled && isBasket" class="icon-v2 shop"></i>
    </div>

    <div *ngIf="!isHeroPageOpen" class="footer-icon d-flex justify-content-center">
      <i *ngIf="!restaurant.published" class="icon picture bg-white" (click)="goToRestaurant()"></i>

      <app-picker
        *ngIf="restaurant.published"
        [classes]="'icon m-large-dark background-none scale-095'"
        [picker]="menuPicker"
        [title]="restaurant.nameView"
        (selectedValue)="openMenu($event)"
      ></app-picker>
    </div>

    <div class="footer-icon">
      <i *ngIf="restaurant.isOwned" class="icon image bg-white" (click)="pickerClick()"></i>

      <app-image-picker *ngIf="!restaurant.isOwned" (save)="goToRestaurantFoodNew($event)"></app-image-picker>
    </div>

    <div class="footer-icon">
      <i *ngIf="restaurant.isOwned" class="icon selector-button bg-white" (click)="pickerClick()"></i>

      <app-picker
        *ngIf="!restaurant.isOwned"
        [classes]="'icon selector-button bg-white'"
        [picker]="restaurantPicker"
        [title]="restaurant.display | abbreviate: false: '' | city: restaurant.cityNameToRemove"
        (selectedValue)="onPickerSelected($event)"
      ></app-picker>
    </div>
  </div>
</div>

<ng-template #qrModalRef let-c="close">
  <app-qr-modal
    appModalCloseOnClick
    [restaurant]="restaurant"
  ></app-qr-modal>
</ng-template>

<ng-template #imageSelectModalRef>
  <app-image-select-modal
    [isInstagram]="true"
    [title]="restaurant.nameView"
    [image]="existImage"
    (save)="onInstagramImageSelect($event)"
  ></app-image-select-modal>
</ng-template>

<ng-template #ownerVerificationModalRef>
  <app-owner-verification
    [restaurant]="restaurant"
  ></app-owner-verification>
</ng-template>

<ng-template #videoReportModalRef>
  <app-video-report-modal
    [restaurant]="restaurant"
    (videoAdded)="onVideoAdded($event)"
    (ownerVerification)="ownerVerification()"
  ></app-video-report-modal>
</ng-template>

<ng-template #videoModalRef let-c="close">
  <app-video-modal
    [restaurant]="restaurant"
    [type]="videoModalType.REGULAR"
    (videoAdded)="onVideoAdded($event)"
  ></app-video-modal>
</ng-template>
