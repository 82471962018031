<ng-container *appIfIsDesktop>
  <div
    class="restaurant-input-container"
    (click)="onFocusInput()"
    [ngClass]="{'focused': inputFocus}"
  >
    <input
      appSearchInputDropdownWidth
      #searchRestaurantInputDesktop
      [ngClass]="inputClass"
      [ngbTypeahead]="searchRest"
      [placement]="'bottom-left'"
      [popupClass]="'search-location-popup search-location-popup-desktop search-location-popup-desktop-restaurant'"
      [focusFirst]="false"
      [editable]="false"
      [inputFormatter]="formatter"
      [resultFormatter]="formatter"
      [resultTemplate]="rt"
      type="text"
      class="form-control form-control-input input-placeholder input-placeholder-hidden"
      id="restaurant-input"
      [placeholder]="mainInputHover ? '        Restaurant name' : ''"
      (selectItem)="selectItem($event)"
      (click)="onFocusInput()"
      (input)="onInputClick($event)"
      (blur)="onInputBlur()"
    >
  </div>

  <ng-template #rt let-r="result" let-t="term">
    <div
      *ngIf="!r.disabled"
      class="d-flex align-items-center"
    >
      <ngb-highlight [result]="r.display" [term]="t"></ngb-highlight>
    </div>
  </ng-template>
</ng-container>

<div *appIfIsMobile class="mobile-icons">
  <i *ngIf="!hiddenRestPlaceholder" class="icon-v2 search-white" (click)="iconClicked()"></i>

  <input
    #searchRestaurantInput
    [ngbTypeahead]="searchRest"
    [ngClass]="inputClass"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-mobile'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    autocomplete="new-search-city"
    class="form-control text-white input-placeholder"
    type="text"
    placeholder="        Restaurant name"
    (input)="restInput($event)"
    (selectItem)="selectItem($event)"
  >
</div>
