<ng-container *ngIf="citySelected?.bigCity">
  <label
    *ngIf="position.isMobile"
    class="search-filters-mobile-label mb-0 position-relative"
  >
    <select
      #districtSelectRef
      appAutoResizeSelect
      required
      aria-label="Neighborhood"
      class="custom-select custom-select-mobile hide-arrow"
      formControlName="districtId"
      (change)="onDistrictChange($event.target)"
      [ngClass]="{'text-red': !districtSelected}"
    >
      <option value="null" hidden [selected]="!districtSelected">Neighborhood</option>

      <ng-container *ngIf="citySelected">
        <ng-container *ngIf="!districtSelect.isGrouped">
          <option *ngFor="let option of districtSelect.options" [value]="option.value">
            {{ option.label }}
          </option>
        </ng-container>

        <ng-container *ngIf="districtSelect.isGrouped">
          <ng-container *ngFor="let option of districtSelect.options;">
            <option *ngIf="districtSelectRef.value == option.value" [value]="option.value">
              {{ option.label }}
            </option>
          </ng-container>

          <optgroup [label]="nearByLabel">
            <ng-container *ngFor="let option of districtSelect.options;">
              <option
                *ngIf="option.group == nearByLabel && districtSelectRef.value != option.value"
                [value]="option.value"
              >
                {{ option.label }}
              </option>
            </ng-container>
          </optgroup>

          <optgroup [label]="otherLabel">
            <ng-container *ngFor="let option of districtSelect.options">
              <option *ngIf="option.group == otherLabel" [value]="option.value">
                {{ option.label }}
              </option>
            </ng-container>
          </optgroup>
        </ng-container>
      </ng-container>
    </select>
  </label>

  <div
    *ngIf="position.isDesktopNavbar"
    class="d-flex"
  >
    <label
      [ngClass]="{'visibility-hidden': stateSelected == null}"
      class="mb-0 text-left mr-1-6rem"
    >
      <ng-select
        #ngSelectDistrictComponent
        appNgSelectOnHover
        [items]="districtSelect.options"
        [searchable]="false"
        [ngClass]="'ng-select-explore-search-title-under-logo dark'"
        groupBy="group"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
        bindLabel="label"
        bindValue="value"
        placeholder="Neighborhood"
        formControlName="districtId"
        (change)="onDistrictChange($event)"
      ></ng-select>
    </label>
  </div>
</ng-container>
