import { Component, OnInit } from '@angular/core';
import { IsDevice } from '../../decorators/is-device.decorator';
import { DeviceType } from '../../models/enums/device-type';
import { BackgroundImageService } from '../../services/background-image.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})

export class PageNotFoundComponent implements OnInit {
  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  logoHover = false;

  constructor(private backgroundImageService: BackgroundImageService) {
  }

  ngOnInit(): void {
    this.backgroundImageService.setCurrentBackgroundImageForMobile();
  }
}
