import { NgModule } from '@angular/core';
import { AddClassIfDesktopDirective } from '../directives/add-class-if-desktop.directive';
import { AddClassIfMobileDirective } from '../directives/add-class-if-mobile.directive';
import { AnimateDirective } from '../directives/animate.directive';
import { AutoResizeSelectDirective } from '../directives/auto-resize-select.directive';
import { AutocompleteOffDirective } from '../directives/autocomplete-off.directive';
import { BackgroundUrlDirective } from '../directives/background-url.directive';
import { BlurImgDirective } from '../directives/blur-img.directive';
import { ClickStopPropagationDirective } from '../directives/click-stop-propagation.directive';
import { DisableUncheckDirective } from '../directives/disable-uncheck.directive';
import { DynamicUrlElementDirective } from '../directives/dynamic-url-element.directive';
import { DynamicUrlDirective } from '../directives/dynamic-url.directive';
import { FitTextDirective } from '../directives/fit-text.directive';
import { FocusDirective } from '../directives/focus.directive';
import { HrefNoneDirective } from '../directives/href-none.directive';
import { IconRelativeZoomDirective } from '../directives/icon-relative-zoom.directive';
import { IfIsDesktopDirective } from '../directives/if-is-desktop.directive';
import { IfIsMobileDirective } from '../directives/if-is-mobile.directive';
import { LazyImgDirective } from '../directives/lazy-img.directive';
import { ModalCloseOnClickPreventDirective } from '../directives/modal-close-on-click-prevent.directive';
import { ModalCloseOnClickDirective } from '../directives/modal-close-on-click.directive';
import { NgSelectOnHoverDirective } from '../directives/ng-select-on-hover.directive';
import { NgbDropdownOnHoverDirective } from '../directives/ngb-dropdown-on-hover.directive';
import { OpacityFullOnHoverDirective } from '../directives/opacity-full-on-hover.directive';
import { RadioGroupNotRequiredDirective } from '../directives/radio-group-not-required.directive';
import { SelectWidthByTextDirective } from '../directives/select-width-by-text.directive';
import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

@NgModule({
  declarations: [
    AddClassIfDesktopDirective,
    AddClassIfMobileDirective,
    AnimateDirective,
    AutocompleteOffDirective,
    BackgroundUrlDirective,
    BlurImgDirective,
    ClickStopPropagationDirective,
    DisableUncheckDirective,
    DynamicUrlDirective,
    DynamicUrlElementDirective,
    FitTextDirective,
    FocusDirective,
    HrefNoneDirective,
    IconRelativeZoomDirective,
    IfIsDesktopDirective,
    IfIsMobileDirective,
    LazyImgDirective,
    ModalCloseOnClickDirective,
    ModalCloseOnClickPreventDirective,
    NgbDropdownOnHoverDirective,
    NgSelectOnHoverDirective,
    OpacityFullOnHoverDirective,
    RadioGroupNotRequiredDirective,
    SelectWidthByTextDirective,
    TextareaAutosizeDirective,
    AutoResizeSelectDirective
  ],
  exports: [
    AddClassIfDesktopDirective,
    AddClassIfMobileDirective,
    AnimateDirective,
    AutocompleteOffDirective,
    BackgroundUrlDirective,
    BlurImgDirective,
    ClickStopPropagationDirective,
    DisableUncheckDirective,
    DynamicUrlDirective,
    DynamicUrlElementDirective,
    FitTextDirective,
    FocusDirective,
    HrefNoneDirective,
    IconRelativeZoomDirective,
    IfIsDesktopDirective,
    IfIsMobileDirective,
    LazyImgDirective,
    ModalCloseOnClickDirective,
    ModalCloseOnClickPreventDirective,
    NgbDropdownOnHoverDirective,
    NgSelectOnHoverDirective,
    OpacityFullOnHoverDirective,
    RadioGroupNotRequiredDirective,
    SelectWidthByTextDirective,
    TextareaAutosizeDirective,
    AutoResizeSelectDirective
  ]
})
export class DirectiveModule {
}
