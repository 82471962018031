import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { Picker } from '../../../models/picker';
import { PickerOption } from '../../../models/picker-option';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss']
})
export class PickerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() picker: Picker;
  @Input() title: string;
  @Input() titleWidth: string;
  @Input() classes: string;
  @Input() value: string;
  @Input() disabled = false;
  @Input() widthByText = false;
  @Input() visibilityDelay = false;
  @Input() rotateIcon = false;

  @Output() selectedValue = new EventEmitter<any>();
  @Output() pickerClick = new EventEmitter<boolean>();

  @ViewChild('selectElement') selectElement: ElementRef;
  @ViewChild('pickerModalRef') pickerModalRef: ElementRef;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  pickerClasses = '';
  isIcon = false;
  paddingLeftZero = false;
  paddingRightZero = false;

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
    this.initClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('classes')) {
      this.initClasses();
    }
  }

  ngAfterViewInit() {
    if (this.visibilityDelay) {
      setTimeout(() => {
        this.selectElement.nativeElement.classList.remove('d-none');
      }, 100);
    }
  }

  openPickerModal() {
    this.pickerClick.emit(true);
    this.modalService.open(this.pickerModalRef, ModalType.CENTER);
  }

  optionSelected(event: any) {
    const selectElement = event.target;
    const selectedValue = selectElement.value;

    selectElement.value = this.title;

    let selectedPickerOption: PickerOption;

    this.picker.optionGroups.forEach(group => {
      const pickerOption = group.options?.find(option => option.value === selectedValue);

      if (pickerOption !== undefined) {
        selectedPickerOption = pickerOption;
      }
    });

    if (selectedPickerOption !== undefined && selectedPickerOption.returnObject) {
      this.selectedValue.emit(selectedPickerOption.returnObject);
    } else {
      this.selectedValue.emit(selectedValue);
    }
  }

  linkClicked(value: string) {
    this.selectedValue.emit(value);
  }

  private initClasses() {
    const classesList = this.classes?.split(' ');

    if (classesList?.includes('icon') || classesList?.includes('icon-v2')) {
      this.isIcon = true;
      classesList.push('background-none text-transparent');
    }

    if (classesList?.includes('pl-0')) {
      this.paddingLeftZero = true;
    }

    if (classesList?.includes('pr-0')) {
      this.paddingRightZero = true;
    }

    this.pickerClasses = classesList?.join(' ');
  }

  onSelectClick(event) {
    event.stopPropagation();

    this.pickerClick.emit(true);
  }
}
