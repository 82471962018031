<div class="navbar-container max-width fixed-bottom d-flex">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <app-navbar-bottom-icon-feed *ngIf="!pageNotFound"></app-navbar-bottom-icon-feed>

    <app-navbar-bottom-icon-explore *ngIf="!pageNotFound"></app-navbar-bottom-icon-explore>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch (insetClick)="onMainMenuClick()">
    <i class="icon main-grey"></i>
  </app-navbar-bottom-semicircle-notch>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <app-navbar-bottom-icon-recent *ngIf="!pageNotFound"></app-navbar-bottom-icon-recent>

    <app-navbar-bottom-icon-user *ngIf="!pageNotFound"></app-navbar-bottom-icon-user>
  </nav>
</div>
