<div *appIfIsMobile class="top-input-modal safe-area-top">
  <div class="bg-white w-100 h-100">
    <div class="row mx-2 my-2" style=" border-radius: 2rem; background: #000 !important; line-height: 0.9rem;">
      <div class="col-12 px-0">
        <app-search-restaurant-input
          inputClass="top-input-modal-input search-filters-mobile-location-input"
          (selectRestaurant)="onSelectRestaurant($event)"
        ></app-search-restaurant-input>

        <div class="search-city-find-me pl-2-25rem-pr-1-75rem safe-area-top">
          <a appHrefNone class="text-white">Nearest</a>
        </div>
      </div>
    </div>

    <div class="row mx-2 my-2" style="border-radius: 2rem; background: #000 !important; line-height: 0.9rem;">
      <div class="col-12 px-0">
        <app-search-city-input
          [clearIcon]="clearIcon"
          (focus)="focusValue($event)"
          (input)="searchInputValue($event)"
          (selectPlace)="onSelectPlace($event)"
          inputClass="top-input-modal-input search-filters-mobile-location-input"
        ></app-search-city-input>

        <div
          *ngIf="clearIcon"
          class="search-city-find-me pr-1-75rem safe-area-top"
        >
          <i class="icon-v2 close-circle-dark" (click)="clearInput()"></i>
        </div>

        <div
          *ngIf="!searchValueExists && !clearIcon"
          class="search-city-find-me pr-1-75rem safe-area-top"
        >
          <a appHrefNone class="text-white" (click)="onFindMe()">Find me</a>
        </div>
      </div>
    </div>

    <div class="row mx-0 bg-white d-flex align-items-center">
      <app-search-filters
        *ngIf="country"
        [position]="searchFiltersPositionMobile"
        [country]="country"
        class="w-100"
      ></app-search-filters>
    </div>
  </div>
</div>
