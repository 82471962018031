import { Component, Input } from '@angular/core';
import { SearchCityService } from '../../../../services/search-city.service';

@Component({
  selector: 'app-navbar-bottom-icon-search',
  templateUrl: './navbar-bottom-icon-search.component.html',
  styles: [
  ]
})
export class NavbarBottomIconSearchComponent {
  @Input() navbarTop = false;

  constructor(private searchCityService: SearchCityService) {
  }

  openCitySearchModal() {
    if (!this.navbarTop) {
      this.searchCityService.openSearchCityModalSubject.next(true);
    }
  }
}
