import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { DeviceType } from '../../../../models/enums/device-type';
import { Place } from '../../../../models/place';
import { GoogleMapsService } from '../../../../services/google-maps.service';

@Component({
  selector: 'app-search-city-input',
  templateUrl: './search-city-input.component.html',
  styleUrls: [
    './search-city-input.component.scss',
    '../search-location-input.scss'
  ]
})
export class SearchCityInputComponent implements OnInit {
  @Input() inputClass: string;
  @Input() mainInputHover: boolean;
  @Input() clearIcon = false;

  @Output() selectPlace = new EventEmitter<Place>();
  @Output() onFindMeClick = new EventEmitter<boolean>();
  @Output() input = new EventEmitter<string>();
  @Output() focus = new EventEmitter<boolean>();

  @ViewChild('searchCityInput', { static: false }) searchCityInput!: ElementRef;
  @ViewChild('searchCityInputDesktop', { static: false }) searchCityInputDesktop!: ElementRef;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  focus$ = new Subject<string>();
  defaultItems: Array<Place> = [];
  hiddenPlaceholder = false;
  inputFocus = false;

  constructor(
    public googleMapsService: GoogleMapsService
  ) {
  }

  ngOnInit(): void {
    if (this.isDesktop) {
      const findMe = new Place();
      findMe.placeId = 'findMe';
      findMe.description = 'Find me';
      findMe.disabled = true;
      findMe.icon = 'icon-v2 find-me-black scale-07';
      this.defaultItems.push(findMe);
    }

    this.focus$
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.focusInput();
      });
  }

  focusInput() {
    if (this.searchCityInputDesktop?.nativeElement) {
      this.searchCityInputDesktop.nativeElement.focus();
    } else if (this.searchCityInput?.nativeElement) {
      this.searchCityInput.nativeElement.focus();
    }
  }

  onFocusInput() {
    this.focusInput();

    this.inputFocus = false;

    this.focus.emit(true);

    this.focus$.next('');

    this.clearIcon = !this.inputFocus;
  }

  onCityInputDesktop(event: any) {
    const inputValue = event.target.value;
    this.input.emit(inputValue);

    if (inputValue === '') {
      this.onFocusInput();
    }
  }

  searchCity: any = (text$: Observable<string>) => {
    return merge(text$, this.focus$).pipe(
      debounceTime(100),
      switchMap(term => {
        if (term.length < 3) {
          return of(this.defaultItems);
        } else {
          return this.googleMapsService.getPredictions(term, 'name').pipe(
            map(places => places.filter(place => !place.disabled)),
            catchError(() => of([]))
          );
        }
      })
    );
  }

  searchCityMobile: any = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.googleMapsService.getPredictions(term, 'name').pipe(
          catchError(() => {
            return of([]);
          })
        )
      ),
      tap()
    );
  }

  selectItem(placeEvent: NgbTypeaheadSelectItemEvent<Place>) {
    if (placeEvent.item.disabled) {
      placeEvent.preventDefault();
    }

    if (placeEvent.item.placeId === 'findMe') {
      this.onFindMeClick.emit(true);
    } else if (placeEvent.item.placeId !== 'location') {
      this.selectPlace.emit(placeEvent.item);
    }
  }

  selectItemMobile(placeEvent: NgbTypeaheadSelectItemEvent<Place>) {
    this.selectPlace.emit(placeEvent.item);
  }

  cityInput(event: any) {
    this.hiddenPlaceholder = event.target.value;
  }

  iconClicked() {
    if (this.searchCityInput) {
      this.searchCityInput.nativeElement.focus();
    }
  }

  onInputBlur(): void {
    this.inputFocus = false;
    this.focus.emit(false);
  }
}
