import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import Swiper, { EffectCoverflow, SwiperOptions, Virtual } from 'swiper';
import { AppService } from '../../../app.service';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentSearchParams } from '../../../decorators/current-search-params.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { CountryWithChild } from '../../../domains/country-with-child';
import { Restaurant } from '../../../domains/restaurant';
import { RestaurantExplore } from '../../../domains/restaurant-explore';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { NumberLocalStorage } from '../../../local-storage/number-local-storage';
import { StringLocalStorage } from '../../../local-storage/string-local-storage';
import { EditRole } from '../../../models/enums/edit-role';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { SearchModalState } from '../../../models/enums/search-modal-state';
import { SearchResultSlideType } from '../../../models/enums/search-result-slide-type';
import { LocalStorageOptions } from '../../../models/local-storage-options';
import { LocationFavorites } from '../../../models/location-favorites';
import { Modal } from '../../../models/modal';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { TypeCategorySection } from '../../../models/type-category-section';
import { UnsplashImage } from '../../../models/unsplash-image';
import { SearchModalStateWrapper } from '../../../models/wrappers/search-modal-state.wrapper';
import { FavoritesService } from '../../../services/favorites.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { SearchCityService } from '../../../services/search-city.service';
import { UnsplashService } from '../../../services/unsplash.service';
import { DomUtils } from '../../../utils/dom-utils';
import { HashUtils } from '../../../utils/hash-utils';

Swiper.use([EffectCoverflow, Virtual]);

@Component({
  selector: 'app-search-mobile',
  templateUrl: './search-mobile.component.html',
  styleUrls: ['./search-mobile.component.scss']
})
export class SearchMobileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() country: CountryWithChild;
  @Input() searchResult: SearchResult;
  @Input() isSearching = false;

  @Output() editRestaurantMenus = new EventEmitter<Restaurant>();

  @ViewChild('previewAndChainsAndDividerModalsRef') previewAndChainsAndDividerModalRef: ElementRef;
  @ViewChild('favoritesModalRef') favoritesModalRef: ElementRef;
  @ViewChild('searchVideoModalRef') searchVideoModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  @CurrentSearchParams() searchParams: SearchParams;

  currentRestaurant: RestaurantExplore;
  expanded = true;
  backgroundImage: UnsplashImage;
  previewAndChainsAndDividerModal: Modal;
  searchFiltersModal: Modal;
  favoritesModal: Modal;
  searchVideoModal: Modal;
  restaurantPositionLocalStorage = new NumberLocalStorage(LocalStorageKey.RESTAURANT_POSITION);
  isHoursEnabled: boolean;
  isFavoriteInit = false;
  searchModalCloseDisableLocalStorage = new BooleanLocalStorage(LocalStorageKey.SEARCH_MODAL_CLOSE_DISABLE);
  hideBottomBar = false;
  newRestaurantOpened = false;

  previewAndChainsConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    effect: 'coverflow',
    parallax: true,
    coverflowEffect: {
      slideShadows: true,
      depth: 100,
      rotate: 50,
      scale: 1,
      stretch: 0,
      modifier: 1.35
    },
    virtual: {
      cache: true,
      addSlidesBefore: 1,
      addSlidesAfter: 1,
      enabled: true
    },
    threshold: 5,
  };

  searchFiltersConfig = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false
  };

  swiper: Swiper;
  locationFavorites: LocationFavorites;
  activeSlideIndex = 0;
  searchResultSlideType = SearchResultSlideType;
  hash: string;
  findMeLoader = false;
  searchModalStateWrapper = new SearchModalStateWrapper(SearchModalState.FAVORITE);
  subscription: Subscription;
  restaurantParamIsSet = false;
  selectRestaurantSubscription: Subscription;
  findMeSubjectSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private navigatorService: NavigatorService,
    private appService: AppService,
    @Inject(DOCUMENT) private _document: Document,
    private favoritesService: FavoritesService,
    private unsplashService: UnsplashService,
    private activatedRoute: ActivatedRoute,
    private searchCityService: SearchCityService
  ) {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });
  }

  ngOnInit() {
    this.hash = HashUtils.getCodePositionUniqueHash();

    const restaurantPosition = this.restaurantPositionLocalStorage.getItem();

    if (restaurantPosition) {
      DomUtils.querySelectorAsync('.section-header').then(() => {
        setTimeout(() => {
          this.appService.scrollTop(
            restaurantPosition, 0, this._document.getElementById('container-search')
          );

          this.restaurantPositionLocalStorage.removeItem();
        });
      });
    }

    if (this.searchResult.groups !== undefined && this.searchResult.exampleRestaurant) {
      this.favoritesService.getFavoritesBySearchParams(this.searchParams)
        .then((locationFavorites: LocationFavorites) => {
          this.locationFavorites = locationFavorites;
        });
    }

    this.selectRestaurantSubscription = this.searchCityService.selectRestaurantSubject.subscribe(res => {
      this.closeSearchFiltersModal();
    });

    this.findMeSubjectSubscription = this.searchCityService.findMeSubject.subscribe(res => {
      this.findMeLoader = true;

      this.closeSearchFiltersModal();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('searchParams')) {
      this.searchParams = changes['searchParams'].currentValue;
    }

    if (this.searchResult.groups !== undefined && this.searchResult.exampleRestaurant) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = this.activatedRoute.queryParams.subscribe(params => {
        if (params['restaurantId']) {
          if (this.restaurantParamIsSet) {
            return;
          }

          this.expanded = true;

          if (params['openPreviewModal']) {
            this.onRestaurantClick(this.searchResult.getRestaurantById(+params['restaurantId']));

            this.previewAndChainsAndDividerModal.onClose().then(() => {
              this.scrollToRestaurant(params['restaurantId']);

              this.navigatorService.removeQueryParam('openPreviewModal');
            });
          } else {
            this.scrollToRestaurant(params['restaurantId']);
          }

          this.restaurantParamIsSet = true;
        }
      });

      this.favoritesService.getFavoritesBySearchParams(this.searchParams)
        .then((locationFavorites: LocationFavorites) => {
          this.locationFavorites = locationFavorites;
        });
    }
  }

  ngOnDestroy(): void {
    this.previewAndChainsAndDividerModal?.close();

    if (!this.searchModalCloseDisableLocalStorage.getItem()) {
      this.searchFiltersModal?.close();
      this.favoritesModal?.close();
      this.searchVideoModal?.close();
    }

    this.findMeLoader = false;

    this.selectRestaurantSubscription.unsubscribe();
    this.findMeSubjectSubscription.unsubscribe();
  }

  private scrollToRestaurant(restaurantId: string) {
    DomUtils.querySelectorAsync('#phantom-restaurant-' + restaurantId).then(element => {
      element?.scrollIntoView({behavior: 'auto', block: 'start', inline: 'nearest'});
    });
  }

  private openPreviewAndChainsAndDividerModal() {
    this.previewAndChainsAndDividerModal = this.modalService.open(
      this.previewAndChainsAndDividerModalRef,
      ModalType.PREVIEW_SWIPER
    );

    this.previewAndChainsAndDividerModal.onClose().then(() => {
      this.currentRestaurant = null;
      this.searchResult.setSlidesLoadedFalse();

      if (!this.newRestaurantOpened) {
        this.hideBottomBar = false;
      }

      this.newRestaurantOpened = false;
    });
  }

  onDividerClick(typeCategorySection: TypeCategorySection) {
    this.activeSlideIndex = this.searchResult.getSlideIndexByDivider(typeCategorySection);

    this.searchResult.setSlideAndNeighborsLoaded(this.activeSlideIndex);

    this.previewAndChainsConfig.initialSlide = this.activeSlideIndex;
    this.openPreviewAndChainsAndDividerModal();
  }

  toggleHeader(headerId = '', exp = false) {
    if (exp) {
      this.expanded = true;
    } else {
      this.expanded = !this.expanded;
    }

    if (this.expanded && headerId !== '') {
      setTimeout(() => {
        const headerEnd = document.querySelector(`#header-${headerId}`);
        headerEnd.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, 100);
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 100);
    }
  }

  @ModalClose()
  closeModal() {}

  onRestaurantClick(restaurant: RestaurantExplore) {
    this.closeModal();

    this.currentRestaurant = restaurant;

    this.isHoursEnabled = false;

    this.activeSlideIndex = this.searchResult.getSlideIndexByRestaurant(restaurant);

    this.favoritesModal?.close();

    this.searchResult.setSlideAndNeighborsLoaded(this.activeSlideIndex);

    this.previewAndChainsConfig.initialSlide = this.activeSlideIndex;

    setTimeout(() => {
      this.hideBottomBar = true;
    }, 100);

    this.openPreviewAndChainsAndDividerModal();
  }

  @Auth()
  goToRestaurantToEditMode(restaurant: RestaurantSimple) {
    const localStorageOptions: LocalStorageOptions = {
      hostname: restaurant.hostname,
      locationId: restaurant.locationIndex,
      userId: this.currentUser?.id
    };

    const isEditModeLocalStorage = new BooleanLocalStorage(LocalStorageKey.IS_EDIT_MODE, localStorageOptions);

    const editRoleLocalStorage = new StringLocalStorage(LocalStorageKey.EDIT_ROLE, localStorageOptions);

    isEditModeLocalStorage.setItem(true);
    editRoleLocalStorage.setItem(EditRole.FAN);

    this.navigatorService.goToRestaurant(restaurant.hostname);
  }

  closeSearchFiltersModal() {
    this.searchFiltersModal?.close();
  }

  onSetPreviewSlideRestaurant(restaurant: RestaurantSimple) {
    this.currentRestaurant = restaurant;
  }

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
  }

  slideToPreview() {
    setTimeout(() => {
      this.swiper.slideTo(1);
    }, 100);
  }

  openFavoriteModal() {
    this.favoritesModal = this.modalService.open(this.favoritesModalRef, ModalType.PREVIEW_SWIPER);

    this.favoritesModal.onClose().then(() => this.searchModalStateWrapper.setValue(SearchModalState.FAVORITE));
  }

  openVideoModal() {
    this.favoritesModal = this.modalService.open(this.favoritesModalRef, ModalType.PREVIEW_SWIPER);

    this.searchModalStateWrapper.setValue(SearchModalState.VIDEO);

    this.favoritesModal.onClose().then(() => this.searchModalStateWrapper.setValue(SearchModalState.FAVORITE));
  }

  getHoursEnabled() {
    if (!this.isFavoriteInit) {
      this.isFavoriteInit = true;

      this.isHoursEnabled = true;
    }

    return this.isHoursEnabled;
  }

  onFavoriteStatusChange() {
    this.favoritesService.favoriteStatusChange(this.searchParams).then((locationFavorites: LocationFavorites) => {
      this.locationFavorites = locationFavorites;
    });
  }

  onEditRestaurantMenus($event: Restaurant) {
    this.editRestaurantMenus.emit($event);
  }

  onRestaurantsPickerSelected(value: any) {
    if (value === 'SEARCH') {
      this.closeModal();

      this.searchCityService.openSearchCityModalSubject.next(true);
    } else {
     this.openRestaurantModal(value);
    }
  }

  openRestaurantModal(restaurant: RestaurantExplore|Restaurant) {
    this.closeModal();

    this.onRestaurantClick(restaurant);
    this.newRestaurantOpened = true;
  }
}
