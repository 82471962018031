import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Post } from '../../../domains/post';
import { PostReport } from '../../../domains/post-report';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { ModalType } from '../../../models/enums/modal-type';
import { PickerCustomOption } from '../../../models/enums/picker-custom-option';
import { PostReportType } from '../../../models/enums/post-report-type';
import { Picker } from '../../../models/picker';
import { PickerConfig } from '../../../models/picker-config';
import { PickerGroup } from '../../../models/picker-group';
import { ModalService } from '../../../services/modal.service';
import { NotificationService } from '../../../services/notification.service';
import { PostService } from '../../../services/post.service';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-post-options',
  templateUrl: './post-options.component.html',
  styleUrls: ['./post-options.component.scss']
})
export class PostOptionsComponent implements OnInit {
  @Input() post: Post;
  @Input() isDark = false;
  @Input() postDesktop = false;
  @Input() postsMobile = false;

  @Output() postEdit = new EventEmitter<boolean>();
  @Output() postDeleted = new EventEmitter<boolean>();

  @ViewChild('postModalRef') postModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  reportPicker = new Picker();
  userPicker = new Picker();
  postReportType = PostReportType;

  constructor(
    private reportService: ReportService,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private postService: PostService,
    private modalService: ModalService
  ) {
    this.userPicker.addDefaultOption(PickerCustomOption.USER_EDIT, 'Edit');
    this.userPicker.addDefaultOption(PickerCustomOption.USER_DELETE, 'Delete');

    const reportGroup = new PickerGroup('Report');
    reportGroup.addOption(PostReportType.SPAM, 'Spam');
    reportGroup.addOption(PostReportType.INAPPROPRIATE, 'Inappropriate');
    reportGroup.addOption(PostReportType.ILLEGAL, 'Illegal');

    this.reportPicker = new Picker(plainToClass(PickerConfig, {
      groups: [reportGroup]
    }));
  }

  ngOnInit(): void {
  }

  @Auth()
  onReportOptionsSelected(postReportType: PostReportType) {
    const postReport = new PostReport();

    postReport.post = this.post;
    postReport.user = this.currentUser;
    postReport.checked = false;
    postReport.type = postReportType;

    this.reportService.reportPost(postReport)
      .subscribe({
        error: error => {
          this.logger.error('ERROR on reporting post: ', error);
        }, complete: () => {
          this.notificationService.reportSubmitted();
        }
      });
  }

  onUserOptionsSelected(value: string) {
    switch (value) {
      case PickerCustomOption.USER_EDIT:
        this.editPost();
        break;
      case PickerCustomOption.USER_DELETE:
        this.modalService.open(this.postModalRef, ModalType.LOGIN_MOBILE);
        break;
    }
  }

  confirmPostDelete() {
    if (this.currentUser?.id !== this.post.user.id) {
      this.onReportOptionsSelected(this.postReportType.SPAM);
    } else {
      this.postService.deletePost(this.post)
        .subscribe(() => {
          this.postDeleted.emit(true);
        });
    }

    this.closeModal();
  }

  @ModalClose()
  closeModal() {}

  editPost() {
    this.postEdit.emit(true);
  }

  openPostModal() {
    this.modalService.open(this.postModalRef, ModalType.LOGIN);
  }
}
