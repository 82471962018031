import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { CountyWithChild } from '../../../../domains/county-with-child';
import { StateWithChild } from '../../../../domains/state-with-child';
import { DeviceType } from '../../../../models/enums/device-type';
import { Labels } from '../../../../models/enums/label';
import { Select } from '../../../../models/select';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { SelectService } from '../../../../services/select.service';

@Component({
  selector: 'app-county-select',
  templateUrl: './county-select.component.html',
  styles: [],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CountySelectComponent implements OnInit, OnDestroy {
  @Input() position: SearchFiltersPositionWrapper;
  @Input() countySelect: Select;
  @Input() stateSelected: StateWithChild;
  @Input() countySelected: CountyWithChild;

  @Output() countyChange = new EventEmitter<any>();

  @ViewChild('countySelectRef') countySelectRef: ElementRef;
  @ViewChild('ngSelectCountyComponent') ngSelectCountyComponent: NgSelectComponent;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  nearByLabel = Labels.NEARBY_LABEL;
  otherLabel = Labels.OTHER_LABEL;

  openNgSelectSubscription: Subscription;
  toggleNgSelectSubscription: Subscription;

  constructor(private selectService: SelectService) {
  }

  ngOnInit() {
    this.openNgSelectSubscription = this.countySelect.openNgSelectSubject.subscribe(() => {
      this.ngSelectCountyComponent.open();
    });

    this.toggleNgSelectSubscription = this.countySelect.toggleNgSelectSubject.subscribe(disable => {
      if (this.isMobile) {
        this.selectService.toggleSelect(this.countySelectRef, disable);
      } else {
        this.selectService.toggleNgSelectComponent(this.ngSelectCountyComponent, disable);
      }
    });
  }

  ngOnDestroy() {
    this.toggleNgSelectSubscription.unsubscribe();
    this.openNgSelectSubscription.unsubscribe();
  }

  onCountyChange($event: any) {
    this.countyChange.emit($event);
  }
}
